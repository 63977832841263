import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { Login, Register, Dashboard, Favorite, Playlist } from './page';

import DetailPlaylist from './page/playlist/detailPlaylist';

import Layout from './components/layout';
import { useContext, useEffect } from 'react';
import { Context } from './store/Context';
import axiosClient from './axiosClient';
import usersApi from './axiosClient/api/users';
import {
  setBanner,
  setGroup,
  setLoveSong,
  setPlaylist,
  setUser,
} from './store/Action';
import playlistsApi from './axiosClient/api/playlists';
import loveSongApi from './axiosClient/api/loveSong';
import { homeApi } from './axiosClient/api/home';

function App() {
  const onFirstLoad = async () => {
    const {
      data: { banner, group },
    } = await homeApi.getHome();
    const token = window.localStorage.getItem('accessToken');
    if (token !== null) {
      const {
        data: { User },
      } = await usersApi.getMe();
      const {
        data: {lovesong},
      } = await loveSongApi.getLoveSongByUser();
      const {
        data: { playlists },
      } = await playlistsApi.getPlaylistByUser();
      return {
        banner,
        group,
        user: User,
        lovesong,
        playlist: playlists,
      };
    }
    return {
      banner,
      group,
    };
  };
  const router = createBrowserRouter([
    {
      path: '/',
      loader: onFirstLoad,
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Dashboard />,
        },
        {
          path: '/favorite',
          element: <Favorite />,
        },
        {
          path: '/playlist',
          element: <Playlist />,
        },
        {
          path: '/playlist/:playlistId',
          element: <DetailPlaylist />,
          loader: async ({ params }) => {
            const response = await playlistsApi.getSongByPlaylist(
              params.playlistId
            );
            return {
              data: response.data,
              playlistId: params.playlistId,
            };
          },
        },
      ],
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/register',
      element: <Register />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
