import React from 'react';

export default function Modal({
  isOpen,
  onClose,
  name,
  onSubmit,
  children,
  btn,
  height,
  width,
}) {
  return (
    <div
      style={{
        height: height ? `${height}px` : 'auto',
        width: width ? `${width}px` : 'auto',
      }}
      className="absolute inset-0 z-[1000] outline-none focus:outline-none top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg"
    >
      {/*header*/}
      <div className="text-2xl font-bold border-b py-2 flex justify-center">
        <span className="mx-auto">{name}</span>
      </div>
      {/*body*/}
      <div className="relative p-6 flex-auto overflow-y-auto h-[70%]">
        {children}
      </div>
      {/*footer*/}
      <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
        {!btn ? (
          <React.Fragment>
            <button
              className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={onClose}
            >
              Đóng
            </button>
            <button
              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={onSubmit}
            >
              Cập nhật
            </button>
          </React.Fragment>
        ) : (
          btn
        )}
        {/* <ToastContainer className="mt-9" /> */}
      </div>
    </div>
  );
}
