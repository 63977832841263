export const SET_SELECTED_PLAYLIST = 'SET_SELECTED_PLAYLIST';
export const ADD_SONG_TO_SELECTED_PLAYLIST = 'ADD_SONG_TO_SELECTED_PLAYLIST';
export const SET_LIST_SONG = 'SET_LIST_SONG';
export const SET_FAVORITE_SONG = 'SET_FAVORITE_SONG';
export const SET_LOVE_SONG = 'SET_LOVE_SONG';
export const ADD_LOVE_SONG = 'ADD_LOVE_SONG';
export const REMOVE_LOVE_SONG = 'REMOVE_LOVE_SONG';
export const UPDATE_LINK_SONG = 'UPDATE_LINK_SONG';
export const SET_PLAYER_TYPE = 'SET_PLAYER_TYPE';
export const SET_PLAYER_PLAYLIST = 'SET_PLAYER_PLAYLIST';
export const PLAYER_TYPE = {
  PLAYLIST: 'playlist',
  ONE: 'one',
  LOVESONG: 'lovesong',
};
export const REPEAT = {
  ONE: 'one',
  ALL: 'all',
  NONE: 'none',
};
export const SET_REPEAT = 'SET_REPEAT';
export const TOGGLE_SHOW_PLAYER = 'TOGGLE_SHOW_PLAYER';
export const HANDLE_PLAY = 'HANDLE_PLAY';
export const HANDLE_PAUSE = 'HANDLE_PAUSE';
export const SET_GROUP = 'SET_GROUP';
export const SET_BANNER = 'SET_BANNER';
export const ADD_PLAYLIST = 'ADD_PLAYLIST';
export const REMOVE_SONG_FROM_PLAYLIST = 'REMOVE_SONG_FROM_PLAYLIST';
export const SET_ADD_SONG_TO_PLAYLIST = 'SET_ADD_SONG_TO_PLAYLIST';
export const ADD_SONG_TO_PLAYLIST = 'ADD_SONG_TO_PLAYLIST';
