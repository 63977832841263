import React, { useContext } from 'react';
import { Context } from '../../../store/Context';
import { setAddSongToPlaylist, setSelectedSong, toggleShowPlayer } from '../../../store/Action';
import { FaPlus } from 'react-icons/fa';
import Modal from '../../Modal';
export default function RenderResult({ data }) {
  const [state, dispatch] = useContext(Context);
  const handleSelectSong = (item) => {
    dispatch(setSelectedSong(item));
    if (!state.player.show) dispatch(toggleShowPlayer());
  };
  if (data.length === 0) {
    return <div>Không tìm thấy bài hát</div>;
  } else {
    const handleAddSongToPlaylist = (e, _id)=>{
      e.stopPropagation();
      dispatch(setAddSongToPlaylist(_id, true))
    }
    return (
      <div>
        {data.map((item, index) => (
          <button
            key={index}
            onClick={() => handleSelectSong(item)}
            className="flex items-center h-full space-x-2 mb-2 w-full hover:bg-[rgba(229,229,229,0.35)] p-2 rounded-lg group"
          >
            <div
              style={{
                width: '64px',
                height: '48px',
                backgroundSize: '64px 48px',
                backgroundImage: `url(${item.thumbnail})`,
                backgroundPosition: 'center',
              }}
              className="rounded-lg flex-1"
            ></div>
            <div className="flex flex-col flex-[5]">
              <p className="text-left line-clamp-1 text-white font-medium">
                {item.name}
              </p>
              <p className="text-left text-sm text-gray-500">
                {item.artist.name}
              </p>
            </div>
            <div onClick={(e)=>handleAddSongToPlaylist(e, item._id)} className="flex-1 group-hover:visible invisible flex justify-center">
              <FaPlus/>
            </div>
          </button>
        ))}
      </div>
    );
  }
}
