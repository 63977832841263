import React from 'react'
import { RiUserVoiceLine } from 'react-icons/ri'

export default function InforSong({selectedSong}) {
  return (
    <div className="flex items-center gap-2 w-[20%]">
        <div
        className='flex-shrink-0'
          style={{
            width: '50px',
            height: '50px',
            backgroundSize: '50px 50px',
            backgroundImage: `url(${selectedSong.thumbnail})`,
            borderRadius: '100%',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>
        <div className="text-white">
          <div className="font-bold line-clamp-1" title={selectedSong.name}>{selectedSong.name}</div>
          <div className="flex items-center">
            <RiUserVoiceLine />
            <div className="ml-1">
              {selectedSong.artist ? selectedSong.artist.name : 'Null'}
            </div>
          </div>
        </div>
      </div>
  )
}
