import React, { useContext, useEffect, useState } from 'react';
import { HiPlay } from 'react-icons/hi';
import { BsHeartFill, BsFillPauseCircleFill } from 'react-icons/bs';
import { Context } from '../../store/Context';
import {
  addLoveSong,
  handlePlay,
  removeLoveSong,
  removeSongFromPlaylist,
  setPlayerType,
  setSelectedSong,
  toggleShowPlayer,
  updateLinkSong,
} from '../../store/Action';
import loveSongApi from '../../axiosClient/api/loveSong';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { FaTimes } from 'react-icons/fa';
import songsApi from '../../axiosClient/api/songs';
import { PLAYER_TYPE } from '../../store/Constant';
import { useLocation } from 'react-router-dom';
import playlistsApi from '../../axiosClient/api/playlists';
import { toast } from 'react-toastify';

export default function RenderListSong({ listSong, onSongClick, onFavorite }) {
  const [state, dispatch] = useContext(Context);
  // const [deleteLoveSong, setDeleteLoveSong] = useState(false);
  const selectedPlaylist = state.playlist.find((p) => p.selected);
  const { selectedSong } = state.player;
  const loveSong = state.loveSong;
  const handleRemoveFavorite = async (_id) => {
    try {
      const response = await loveSongApi.handleRemoveLoveSong(_id);
      const { data } = response;
      dispatch(removeLoveSong(data.song));
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd = async (_id) => {
    try {
      const response = await loveSongApi.handleLoveSongById(_id);
      const { data } = response;
      dispatch(addLoveSong(data.song));
      // onFavorite(newData);
    } catch (error) {}
  };
  const path = useLocation().pathname;
  const setSong = async (l) => {
    dispatch(setSelectedSong(l));
    if (!state.player.show) {
      dispatch(toggleShowPlayer());
    }
    dispatch(handlePlay());
    if (!path.includes('favorite')) dispatch(setPlayerType(PLAYER_TYPE.ONE));
    else {
      dispatch(setPlayerType(PLAYER_TYPE.LOVESONG));
    }
  };
  const handleRemoveSongFromPlaylist = async (playlistId, songId) => {
    try {
      await playlistsApi.removeSong(playlistId, songId);

      dispatch(removeSongFromPlaylist(playlistId, songId));
    } catch (error) {
      toast.error('Xóa bài hát khỏi playlist thất bại. Vui lòng thử lại sau!');
    }
  };
  return (
    <div className="relative overflow-x-auto w-full">
      <table className="w-full text-left ">
        <thead className="text-lg ">
          <tr className="flex">
            <th scope="col" className="py-3 flex-1 ">
              <span className="mx-2">#</span>
            </th>
            <th scope="col" className="py-3 flex-[5]">
              Bài hát
            </th>
            <th scope="col" className="py-3 flex-[2]">
              Nghệ sĩ
            </th>
            <th className="flex-[1]"></th>
          </tr>
        </thead>
        <tbody>
          {listSong.map((l, i) => (
            <tr
              className={`border-b ${
                l && selectedSong && l._id === selectedSong._id
                  ? 'bg-[#aee9c5]'
                  : 'transparent '
              } hover:bg-[#aee9c5] group flex`}
              key={i}
            >
              <td className="flex-1 flex items-center">
                <div className="block group-hover:hidden px-2">{i + 1}</div>
                <button
                  className="hidden group-hover:block bg-transparent z-50"
                  onClick={() => setSong(l)}
                >
                  {state.player.isPlay ? (
                    <BsFillPauseCircleFill
                      className="invisible group-hover:visible"
                      size="2rem"
                    />
                  ) : (
                    <HiPlay
                      className="invisible group-hover:visible"
                      size="2rem"
                    />
                  )}
                </button>
              </td>
              <td className="py-2 flex-[5]">
                <div className="flex items-center">
                  <img
                    src={l.thumbnail}
                    alt=""
                    className="w-[35px] h-[35px] rounded-lg mr-2"
                  />
                  {l.name}
                </div>
              </td>
              <td className="py-2 flex-[2]">{l.artist.name}</td>
              <td className="py-2 group flex gap-2 items-center flex-1">
                {!loveSong.map((l) => l._id).includes(l._id) ? (
                  <AiOutlineHeart
                    className="cursor-pointer"
                    title="Thêm vào mục yêu thích"
                    onClick={() => handleAdd(l._id)}
                  />
                ) : (
                  <AiFillHeart
                    className="cursor-pointer"
                    title="Xóa khỏi mục yêu thích"
                    onClick={() => handleRemoveFavorite(l._id)}
                  />
                )}
                {selectedPlaylist &&
                  selectedPlaylist.owner._id === state.user._id && (
                    <button
                      onClick={() =>
                        handleRemoveSongFromPlaylist(
                          selectedPlaylist._id,
                          l._id
                        )
                      }
                      className="hidden group-hover:block"
                    >
                      <FaTimes />
                    </button>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
