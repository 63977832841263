import React, { useContext, useEffect, useRef } from 'react';
import Navigation from '../bar/Navigation';
import Topbar from '../bar/Topbar';
import { Outlet, useLoaderData } from 'react-router-dom';
import Player from '../Player';
import { Context } from '../../store/Context';
import usersApi from '../../axiosClient/api/users';
import {
  addSongToPlaylist,
  setAddSongToPlaylist,
  setBanner,
  setGroup,
  setListSong,
  setLoveSong,
  setPlaylist,
  setUser,
} from '../../store/Action';
import playlistsApi from '../../axiosClient/api/playlists';
import { ToastContainer, toast } from 'react-toastify';
import loveSongApi from '../../axiosClient/api/loveSong';
import Modal from '../Modal';
import { useState } from 'react';

export default function Layout() {
  const [state, dispatch] = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const playlists = state.playlist;
  const PF = process.env.REACT_APP_SERVER_URL;
  const formRef = useRef();
  const data = useLoaderData();
  useEffect(()=>{
    if(data){
      const {banner, group, playlist, user,lovesong} = data;
      dispatch(setBanner(banner));
      dispatch(setGroup(group));
      dispatch(setPlaylist(playlist));
      dispatch(setUser(user));
      dispatch(setLoveSong(lovesong));
    }
  },[data])
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(state.playlist);
    const _ids = Array.from(e.target.select)
      .filter((x) => x.checked)
      .map((x) => x.defaultValue);
    Promise.all(
      _ids.map(async (id) => {
        const res = await playlistsApi.addSongToPlaylist(
          id,
          state.home.isAddSongToPlaylist._id
        );
        dispatch(addSongToPlaylist(id, res.data.song));
      })
    )
      .then((res) => {
        toast.success('Thêm bài hát vào playlist thành công!', {
          position: 'top-right',
          autoClose: 500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .catch((error) => {
        if(error.response.status === 409){
          toast.error('Bài hát đã tồn tại!', {
          position: 'top-right',
          autoClose: 500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        }else{

          toast.error('Thêm bài hát vào playlist thất bại!', {
              position: 'top-right',
              autoClose: 500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
      }).finally(()=>{
        console.log("        !23")
        document.querySelectorAll("[name=select]").forEach(x=>x.checked = false)
      })
      
  };
  return (
    <div className="h-screen relative">
      <Topbar />
      <div className="flex h-[calc(100%-4rem)]">
        <Navigation
          playlist={state.playlist}
          className="w-[12rem] py-3 box-border flex flex-col items-center 
      justify-between left-0 z-[1] h-full border-r border-[#e5e5e5] shadow-xl"
        />
        <div className="w-[calc(100%-10rem)] overflow-y-auto">
          <Outlet />
          {
            // state.player.selectedSong && <Player/>
            state.player.show && <Player />
          }
        </div>
      </div>
      {state.home.isAddSongToPlaylist.show && (
        <React.Fragment>
          <form onSubmit={handleSubmit}>
            <Modal
              height={400}
              width={500}
              name={'Thêm bài hát vào playlist'}
              isOpen={state.home.isAddSong}
              onClose={() => dispatch(setAddSongToPlaylist('', false))}
              btn={
                <React.Fragment>
                  <button
                    onClick={() => dispatch(setAddSongToPlaylist('', false))}
                    className="bg-red-500 rounded-lg outline-none border-none p-2 px-4 text-white"
                  >
                    Hủy
                  </button>
                  <button
                    type="submit"
                    // onClick={() => dispatch(setAddSongToPlaylist('', false))}
                    onClick={() => {
                      if (formRef.current) {
                        formRef.current.submit();
                      }
                    }}
                    className="bg-green-500 rounded-lg outline-none border-none p-2 px-4 text-white ml-2"
                  >
                    Thêm
                  </button>
                </React.Fragment>
              }
              children={
                isLoading ? (
                  <div>
                    {[0, 1, 2, 3].map((x) => (
                      <div
                        key={x}
                        className="flex animate-pulse flex-row items-center h-full space-x-2 mb-2 justify-center"
                      >
                        <div className="w-[50px] h-[50px] bg-blue-400 rounded-[10px]"></div>
                        <div className="flex flex-col space-y-3 flex-1">
                          <div className="w-[60%] bg-blue-400 h-4 rounded-md "></div>
                          <div className="w-16 bg-blue-400 h-3 rounded-md "></div>
                        </div>
                        <input
                          disabled
                          className="ml-auto animate-pulse "
                          type="checkbox"
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  playlists.map((pl) => (
                    <div className="flex gap-2">
                      <div
                        style={{
                          backgroundImage: `url(${PF}/images/${pl.thumbnail})`,
                          width: '50px',
                          height: '50px',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                          backgroundRepeat: 'no-repeat',
                          borderRadius: '10px',
                        }}
                        className="mb-2"
                      ></div>
                      <div className="flex flex-col justify-center items-start">
                        <div className="line-clamp-2">{pl.title}</div>
                        <p className="text-sm text-gray-400">
                          Bài hát:{' '}
                          {pl.songLength}
                        </p>
                      </div>
                      <input
                        name="select"
                        value={pl._id}
                        className="ml-auto"
                        type="checkbox"
                      />
                    </div>
                  ))
                )
              }
            ></Modal>
          </form>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </React.Fragment>
      )}
      <ToastContainer />
    </div>
  );
}
