import React from 'react';

export default function Skeleton() {
  return (
    <div className="flex animate-pulse flex-row items-center h-full space-x-2 mb-2">
        <div className="w-8 bg-blue-400 h-8 rounded-full"></div>
        <div className="flex flex-col space-y-3 flex-1">
          <div className="w-[60%] bg-blue-400 h-2 rounded-md "></div>
          <div className="w-16 bg-blue-400 h-2 rounded-md "></div>
        </div>
      </div>
  );
}
