import React, { useContext, useEffect, useState } from 'react';
import { Link, useLoaderData, useLocation, useParams } from 'react-router-dom';
import playlistsApi from '../../axiosClient/api/playlists';
import { Context } from '../../store/Context';
import { format } from 'date-fns';
import RenderListSong from '../../components/RenderListSong';
import EditPlaylist from './EditPlaylist';
import InfoPlaylist from './InfoPlaylist';
import SuggestSongs from '../../components/SuggestSongs';
import { setListSong, setSelectedPlaylist } from '../../store/Action';
import { addPlaylist } from '../../store/Action';
const moment = require('moment');
require('moment/locale/vi');

export default function DetailPlaylist(props) {
  const [state, dispatch] = useContext(Context);
  const [isOpen, setIsOpen] = useState(false);
  const data = useLoaderData();
  const param = useParams();
  const path = param.playlistId;
  const selectedPlaylist = state.playlist.find((p) => p.selected);
  const playlist = state.playlist.find((p) => p._id === path);
  useEffect(() => {
    (async () => {
        // const response = await playlistsApi.getPlaylistById(data.playlistId);
        // dispatch(addPlaylist(response.data.playlist));
      // const isExist = state.playlist.includes((p) => p._id === data.playlistId);
      // if (!isExist) {
        
      // }
      if (data) {
        dispatch(setListSong(data.data, data.playlistId));
      }
    })();
  }, [data, state.playlist.length]);

  const handleOpenModal = () => {
    setIsOpen(true);
  };
  // useEffect(() => {
  //   const selectedPlaylist = state.playlist.find((s) => s.selected);
  //   if (state.playlist.length > 0) {
  //     if (!selectedPlaylist) {
  //     } else {
  //       if (selectedPlaylist) {

  //       }
  //     }
  //   }
  // }, [state.playlist]);
  useEffect(() => {
    const playlists = state.playlist;
    const playlist = playlists.find((p) => p._id === path);
    if (playlists.length > 0) {
      if (playlist === undefined) {
        (async () => {
          const response = await playlistsApi.getPlaylistById(path);
          const { listSong, ...data } = response.data.playlist;
          dispatch(
            addPlaylist({
              ...data,
              selected: false,
            })
          );
          dispatch(setSelectedPlaylist(path));
        })();
      } else {
        dispatch(setSelectedPlaylist(path));
      }
    }
  }, [path, state.playlist.length]);
  return (
    <React.Fragment>
      <div className="p-8 flex gap-8">
        <div className="gap-4 flex-1">
          {selectedPlaylist && (
            <InfoPlaylist
              playlist={selectedPlaylist}
              onOpen={handleOpenModal}
              length={
                selectedPlaylist.listSong ? selectedPlaylist.listSong.length : 0
              }
              user={selectedPlaylist.owner}
            />
          )}
          {isOpen && playlist && (
            <EditPlaylist
              playlist={playlist}
              onClose={() => setIsOpen(false)}
            />
          )}
        </div>
        <div className="flex-[2]">
          {selectedPlaylist &&
            (selectedPlaylist.listSong === undefined ||
            selectedPlaylist.listSong.length === 0 ? (
              <SuggestSongs />
            ) : (
              <RenderListSong listSong={selectedPlaylist.listSong} />
            ))}
        </div>
      </div>
    </React.Fragment>
  );
}
